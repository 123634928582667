<template>
  <v-card elevation="24">
    <v-card-text>
      <validation-observer ref="observer" v-slot="{ passes }">
        <v-form @submit.prevent="passes()">
          <v-row>
            <v-col cols="6" md="3" lg="2">
              <v-menu
                ref="menuStartDate"
                v-model="menu.startDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider v-slot="{ errors }" name="startDate" rules="required">
                    <v-text-field
                      :value="fStartDate"
                      :label="$t('fields.startDate')"
                      :prepend-inner-icon="icons.mdiCalendar"
                      :hint="$t('wot.formats.date')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                      outlined
                      dense
                      readonly
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu.startDate = false"
                  no-title
                  show-adjacent-months
                  first-day-of-week="1"
                >
                  <v-btn text block color="primary" @click="clickToday(1)">
                    {{ $t('today') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3" lg="2">
              <v-menu
                ref="menuEndDate"
                v-model="menu.endDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider v-slot="{ errors }" name="endDate" rules="required">
                    <v-text-field
                      :value="fEndDate"
                      :label="$t('fields.endDate')"
                      :prepend-inner-icon="icons.mdiCalendar"
                      :hint="$t('wot.formats.date')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                      outlined
                      dense
                      readonly
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu.endDate = false"
                  no-title
                  show-adjacent-months
                  first-day-of-week="1"
                >
                  <v-btn text block color="primary" @click="clickToday(2)">
                    {{ $t('today') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" lg="2">
              <validation-provider v-slot="{ errors }" name="billed" rules="required">
                <v-select
                  v-model="billed"
                  :items="billedOptions"
                  item-text="text"
                  item-value="value"
                  :label="$t('fields.billed')"
                  :prepend-inner-icon="icons.mdiReceipt"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="listType === 'transporter'">
              <validation-provider v-slot="{ errors }" name="u_transp">
                <v-autocomplete
                  v-model="u_transp"
                  :items="transporters"
                  item-text="nome"
                  :label="$t('fields.u_transp')"
                  :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  return-object
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="3" md="2" v-if="listType === 'provider' || listType === 'agent'">
              <validation-provider v-slot="{ errors }" name="u_mata">
                <v-autocomplete
                  v-model="u_mata"
                  :items="woods"
                  :label="$t('fields.u_mata')"
                  :prepend-inner-icon="icons.mdiPineTree"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  @change="changeWoods"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" lg="2" v-if="listType === 'agent'">
              <validation-provider v-slot="{ errors }" name="u_agent">
                <v-autocomplete
                  v-model="u_agent"
                  :items="agents"
                  item-text="nome2"
                  :label="$t('fields.u_agent')"
                  :prepend-inner-icon="icons.mdiHandCoinOutline"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  return-object
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" lg="2" v-if="listType === 'provider'">
              <validation-provider v-slot="{ errors }" name="u_nmfornec">
                <v-autocomplete
                  v-model="u_nmfornec"
                  :items="providers"
                  item-text="nome"
                  :label="$t('fields.u_nmfornec')"
                  :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  return-object
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="listType === 'client'">
              <validation-provider v-slot="{ errors }" name="client">
                <v-autocomplete
                  v-model="client"
                  :items="clients"
                  item-text="nome"
                  :label="$t('fields.client')"
                  :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                  :error-messages="errors"
                  outlined
                  dense
                  clearable
                  return-object
                />
              </validation-provider>
            </v-col>
            <template v-if="listType === 'teams'">
              <v-col cols="12" sm="6" md="4" lg="2">
                <validation-provider v-slot="{ errors }" name="u_equipa">
                  <v-autocomplete
                    v-model="u_equipa"
                    :items="teams"
                    item-text="nome"
                    :label="$t('fields.u_equipa')"
                    :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                    :error-messages="errors"
                    outlined
                    dense
                    clearable
                    return-object
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <validation-provider v-slot="{ errors }" name="u_tarefa">
                  <v-autocomplete
                    v-model="u_tarefa"
                    :items="tasks"
                    :label="$t('fields.u_tarefa')"
                    :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                    :error-messages="errors"
                    outlined
                    dense
                    clearable
                    return-object
                  />
                </validation-provider>
              </v-col>
            </template>
            <v-col cols="6" sm="3" lg="2" v-if="listType === 'global'">
              <validation-provider v-slot="{ errors }" name="billed" rules="required">
                <v-select
                  v-model="hasTeams"
                  :items="hasTeamsOptions"
                  item-text="text"
                  item-value="value"
                  :label="$t('hasTeams')"
                  :prepend-inner-icon="icons.mdiAccountGroup"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="listType === 'global'">
              <validation-provider v-slot="{ errors }" name="title">
                <v-text-field
                  v-model="globalTitle"
                  :label="$t('fields.title')"
                  :error-messages="errors"
                  outlined
                  counter="100"
                  maxlength="100"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col class="d-flex justify-end">
              <json-excel
                v-if="excelData && excelFields"
                style="cursor: pointer"
                :data="excelData"
                :fields="excelFields"
                class="mt-1"
              >
                <v-icon color="primary">{{ icons.mdiMicrosoftExcel }}</v-icon>
              </json-excel>
              <v-btn
                v-if="hasPdf"
                color="primary"
                icon
                @click="$emit('download-pdf')"
                class="mr-5"
              >
                <v-icon>{{ icons.mdiFilePdfBox }}</v-icon>
              </v-btn>
              <v-btn
                v-if="hasPdf && listType === 'transporter'"
                color="primary"
                icon
                @click="$emit('download-pdf', true)"
                class="mr-5"
              >
                <v-icon>{{ icons.mdiFilePdfBox }}</v-icon> {{ $t('buttonPlusFt') }}
              </v-btn>
              <v-btn
                v-if="isList"
                color="primary"
                @click="setEmailDialog(true)"
              >
                {{ listType === 'client' ? $t('sendEmail') : $t('aSend') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable camelcase */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiCalendar,
  mdiReceipt,
  mdiCardAccountDetailsOutline,
  mdiFilePdfBox,
  mdiPineTree,
  mdiHandCoinOutline,
  mdiMicrosoftExcel,
  mdiAccountGroup,
} from '@mdi/js';
import JsonExcel from 'vue-json-excel';
import functions from '@/resources/functions';
import dayjs from '@/plugins/dayjs';

export default {
  mixins: [functions],
  emits: ['filter-load-orders', 'open-list-modal', 'download-pdf'],
  props: {
    listType: {
      type: String,
      required: true,
      validator(value) {
        return ['list', 'park', 'client', 'transporter', 'provider', 'agent', 'teams', 'global', 'certifiedMaterial'].includes(value);
      },
    },
    isList: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasPdf: {
      type: Boolean,
      required: false,
      default: false,
    },
    excelData: {
      type: Array,
      required: false,
      default: null,
    },
    excelFields: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    JsonExcel,
  },
  data: () => ({
    menu: { startDate: false, endDate: false },
    icons: {
      mdiCalendar,
      mdiReceipt,
      mdiCardAccountDetailsOutline,
      mdiFilePdfBox,
      mdiPineTree,
      mdiHandCoinOutline,
      mdiMicrosoftExcel,
      mdiAccountGroup,
    },
  }),
  methods: {
    ...mapActions('loadOrders', ['showWoodProvider', 'showWoods', 'showWoodAgent']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setLoadOrdersFilter', 'setEmailDialog']),
    clickToday(n) {
      if (n === 1) {
        this.startDate = dayjs().format('YYYY-MM-DD');
      } else {
        this.endDate = dayjs().format('YYYY-MM-DD');
      }
    },
    async changeWoods() {
      this.setLoading(true);

      if (this.u_mata) {
        if (this.listType === 'provider') {
          await this.showWoodProvider(this.u_mata);

          this.u_nmfornec = this.woodProvider;
        } else if (this.listType === 'agent') {
          await this.showWoodAgent(this.u_mata);

          this.u_agent = this.woodAgent;
        }
      }

      this.setLoading(false);
    },
  },
  computed: {
    ...mapFields('loadOrders', ['filters.loadOrders.globalTitle']),
    ...mapGetters('clients', ['clients']),
    ...mapGetters('loadOrders', ['loadOrdersFilter', 'loadOrdersFilterErrors', 'transporters', 'providers', 'teams', 'tasks', 'woods', 'woodProvider', 'woodAgent']),
    filterToWatch() {
      const {
        startDate,
        endDate,
        billed,
        u_transp,
        u_nmfornec,
        u_equipa,
        u_tarefa,
        client,
        u_mata,
        u_agent,
        hasTeams,
      } = this.loadOrdersFilter;

      return {
        startDate,
        endDate,
        billed,
        u_transp,
        u_nmfornec,
        u_equipa,
        u_tarefa,
        client,
        u_mata,
        u_agent,
        hasTeams,
      };
    },
    billedOptions() {
      const options = [];

      Object.entries(this.$t('wot.filterBilled')).forEach((entry) => {
        const [key, value] = entry;

        options.push({ value: key, text: value });
      });

      if (['transporter', 'provider', 'teams', 'client'].includes(this.listType)) {
        options.splice(2, 1);
      }

      return options;
    },
    agents() {
      return this.providers.filter(({ nome2 }) => nome2 !== '');
    },
    hasTeamsOptions() {
      return Object.entries(this.$t('wot.hasTeams')).map(([key, value]) => ({ value: key, text: value }));
    },
    startDate: {
      get() {
        return this.loadOrdersFilter.startDate;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'startDate', data: value });
      },
    },
    endDate: {
      get() {
        return this.loadOrdersFilter.endDate;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'endDate', data: value });
      },
    },
    billed: {
      get() {
        return this.loadOrdersFilter.billed;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'billed', data: value });
      },
    },
    hasTeams: {
      get() {
        return this.loadOrdersFilter.hasTeams;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'hasTeams', data: value });
      },
    },
    u_transp: {
      get() {
        return this.loadOrdersFilter.u_transp;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'u_transp', data: value });
      },
    },
    u_nmfornec: {
      get() {
        return this.loadOrdersFilter.u_nmfornec;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'u_nmfornec', data: value });
      },
    },
    u_equipa: {
      get() {
        return this.loadOrdersFilter.u_equipa;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'u_equipa', data: value });
      },
    },
    u_agent: {
      get() {
        return this.loadOrdersFilter.u_agent;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'u_agent', data: value });
      },
    },
    u_tarefa: {
      get() {
        return this.loadOrdersFilter.u_tarefa;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'u_tarefa', data: value });
      },
    },
    u_mata: {
      get() {
        return this.loadOrdersFilter.u_mata;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'u_mata', data: value });
      },
    },
    client: {
      get() {
        return this.loadOrdersFilter.client;
      },
      set(value) {
        this.setLoadOrdersFilter({ key: 'client', data: value });
      },
    },
    fEndDate() {
      return this.formatDate(this.endDate);
    },
    fStartDate() {
      return this.formatDate(this.startDate);
    },
  },
  watch: {
    u_nmfornec(newVal) {
      this.showWoods({ u_nmfornec: newVal?.nome });
    },
    u_agent(newVal) {
      this.showWoods({ u_agent: newVal?.nome });
    },
    filterToWatch: {
      deep: true,
      handler() {
        this.$emit('filter-load-orders');
      },
    },
    loadOrdersFilterErrors() {
      this.$refs.observer.setErrors(this.loadOrdersFilterErrors);
    },
  },
  mounted() {
    if (['list', 'global', 'certifiedMaterial', 'teams'].includes(this.listType)) {
      this.billed = 'all';
    }
  },
};
</script>
